<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>

            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-text-field hide-details label="* Disciplina:" v-model="registro.disciplina" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'

export default {
    name: "DisciplinaForm",
    props : ['id_disciplina'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null
        }
    },
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        salvar() {

            if(!this.id_disciplina) {

                axios.post(`${this.baseUrl}disciplina/insert`, {...this.registro, cep : this.mascaraRemover(this.registro.cep), telefone : this.mascaraRemover(this.registro.telefone), whatsapp : this.mascaraRemover(this.registro.whatsapp)}).then( (res) => {

                    if(res.data.erro == true) {

                        this.erro = res.data.mensagem
                    }
                    else {

                        this.erro = null
                        this.$router.push({path : '/disciplina/gerenciar/' + res.data.id_disciplina})
                    }
                })
            }
            else {

                axios.post(`${this.baseUrl}disciplina/update`, {...this.registro}).then( (res) => {

                    if(res.data.erro == true) {

                        this.erro = res.data.mensagem
                    }
                    else {

                        this.erro = null
                        this.$router.push({path : '/disciplina/gerenciar/' + this.id_disciplina})
                    }
                })
            }
        },
        get() {
          return axios.post(`${this.baseUrl}disciplina/get`, {id_disciplina : this.id_disciplina}).then( (res) => {

            this.registro = res.data
          })
        },
        async init() {
            this.registro = {}

            if(this.id_disciplina) {

                await this.get()
            }
        }
    },

    watch : {

    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
